.#{$css-namespace}catalog-events-banner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 2.7rem $indent__l $indent__base;
    background: $color-blue-background;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: -2rem;
        border-bottom: 2rem solid white;
        border-right: 2rem solid white;
        width: 0;
        height: 4rem;
        transform: skew(-45deg);
    }

    &-img {
        padding: 0 !important;
        background: none;
    }

    @include max-screen($screen__l) {
        flex-direction: column;
    }

    @include min-screen($screen__l) {
        justify-content: space-between;
        padding: 4.2rem 9rem 4.2rem 5rem;
    }

    &_info-wrapper {
        @include max-screen($screen__l) {
            text-align: center;
        }

        @include min-screen($screen__l) {
            display: flex;
            align-items: center;
        }
    }

    &_text {
        @include max-screen($screen__l) {
            padding: $indent__base 0 1.5rem;
        }
    }

    &_title,
    &_subtitle {
        margin: 0;
        color: $color-white;
    }

    &_title {
        font-size: 2.2rem;
        font-weight: $font-weight-bold;
        font-family: $font-secondary;

        @include min-screen($screen__l) {
            margin-bottom: $indent__xs;
            font-size: $font-size-xxl;
        }

        @include max-screen($screen__l) {
            margin-bottom: 1.5rem;
        }
    }

    &_subtitle {
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        font-family: $font-default;
        line-height: 1.57;

        @include min-screen($screen__l) {
            font-size: $font-size-xl;
            line-height: 1.2;
        }
    }

    .dcfonts-icons {
        font-size: 4.8rem;
        color: $color-secondary;

        @include min-screen($screen__l) {
            margin-right: 5rem;
            font-size: 7rem;
        }
    }

    .#{$css-namespace}btn {
        min-width: 21.8rem;
        justify-content: center;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        color: $color-primary;
        background: $color-white;
    }
}
