[data-category-name='pa'] {
    border-right-color: nth(map-get($gamme-list, 'acier'), 1);
}

[data-category-name='pb'] {
    border-right-color: nth(map-get($gamme-list, 'amenagements-exterieurs'), 1);
}

[data-category-name='pc'] {
    border-right-color: nth(map-get($gamme-list, 'consommables'), 1);
}

[data-category-name='pd'] {
    border-right-color: nth(map-get($gamme-list, 'epi'), 1);
}

[data-category-name='pe'] {
    border-right-color: nth(map-get($gamme-list, 'equipements-atelier'), 1);
}

[data-category-name='pf'] {
    border-right-color: nth(map-get($gamme-list, 'equipements-chantier'), 1);
}

[data-category-name='ph'] {
    border-right-color: nth(map-get($gamme-list, 'outillage'), 1);
}

[data-category-name='pi'] {
    border-right-color: nth(map-get($gamme-list, 'plomberie'), 1);
}

[data-category-name='pj'] {
    border-right-color: nth(map-get($gamme-list, 'quincaillerie'), 1);
}

[data-category-name='pl'] {
    border-right-color: nth(map-get($gamme-list, 'visserie'), 1);
}
