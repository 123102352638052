// Common values

//$mainPrimaryColor: #1d1d1b;
//$subPrimaryColor: #03488f;
//$subPrimaryColor-lighten: #0089d0;
$subPrimaryColor-darken: #001020; // TODO Remove all redefinitions of the same hexa
$altPrimaryColor: #ffb400;
$subAltPrimaryColor: #858585; // Same as $color-gray52
$subAltPrimaryColor-darker: #717171; // TODO Remove all redefinitions of the same hexa
$subAltPrimaryColor-lighten: #c9ccd0; // TODO Remove all redefinitions of the same hexa
$shadowColor: #dde2e6; // TODO Remove all redefinitions of the same hexa

$mainSecondaryColor: #32d355; // TODO Remove all redefinitions of the same hexa
//$subSecondaryColor: #e40521;

$mainNeutralColor: #bfbfbf; // TODO: refacto same as $color-disabled-normal
//$subNeutralColor: #e5e5e5;
//$altNeutralColor: #f0f0f0;
//$subAltNeutralColor: #e5f6fa;

$defaultColor: $color-white;

$deleteColor: #ea1a1a;

$colorAgency: #ff8200;

// status
//$colorSucces: #27ae60;
$colorWarning: #ff6600; // TODO Remove all redefinitions of the same hexa
$colorDanger: $deleteColor;
//$colorInfo: $color-sub-secondary;

// Background values

//$mainBackgroundColor: $color-sub-secondary;
$subBackgroundColor: $altPrimaryColor;
$altBackgroundColor: $color-gray-light7;
$lightBlueBackgroundColor: #e4f0fc;

// Buttons values

$defaultButtonBackgroundColor: $color-blue1;
//$mainButtonBackgroundColor: $color-sub-secondary;
$subButtonBackgroundColor: $subBackgroundColor;
$alButtonBackgroundColor: $altBackgroundColor;
$primaryButtonPopWishList: #03488f; // TODO: Refacto, same as $color-sub-secondary

// Border values

$defaultBorderColor: $color-blue1;
$mainBorderColor: $color-gray-light7;
//$subBorderColor: $color-sub-secondary;
$altBorderColor: $mainNeutralColor;

// Font value

$mainFontColor: $color-gray-light7;
//$subFontColor: $color-sub-secondary;
$altFontColor: $defaultColor;

$mainButtonFontColor: $altFontColor;
//$subButtonFontColor: $color-sub-secondary;
$altButtonFontColor: $mainFontColor;

// Input value

$mainInputColor: $mainNeutralColor;
