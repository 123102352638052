$slider-padding: 2rem;
$slider-figcaption-height: 10.2rem;

.searchterms-index-index {
    .page-title-wrapper {
        .catalogsearch-refine {
            font-size: $font-size-xl;
            line-height: 1.25;
        }
    }
}

.#{$css-namespace}page_acier-redirection {
    @include max-screen($screen__m) {
        margin-top: $space-5;
    }

    h2.#{$css-namespace}title[data-ui-title-level='2'] {
        // To fit with Figma
        font-size: $font-size-xxl;
    }

    .container {
        max-width: 128.5rem; // Specific container size cf Figma
    }

    .container-cards {
        display: flex;
        flex-wrap: wrap;
        gap: $space-5;

        @include min-screen($screen__m) {
            gap: 0 $space-5;
        }

        .card {
            width: 100%;
            background-color: $color-white;

            @include min-screen($screen__m) {
                display: flex;
                flex: 1;
                flex-direction: column;
            }
        }

        .image-wrapper {
            height: 0;
            padding-bottom: 56.25%;
            position: relative;
        }

        .image-cards {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
        }

        .text-card {
            background: $color-white;
            padding: 3.65rem $space-5; // Fit to Figma
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            p.#{$css-namespace}title {
                color: $color-secondary;
            }

            h2,
            span {
                margin-top: $space-2;
            }

            ul {
                margin-top: $space-2;
                padding-left: $space-3;

                li {
                    margin-bottom: $space-1;
                }
            }

            .#{$css-namespace}btn {
                // TODO : Refacto needed using w-full
                margin-top: auto;
                width: 100%;
                justify-content: center;
            }
        }
    }

    .#{$css-namespace}cms-seo-block {
        margin-top: $space-5;
        padding: 0 2rem;

        ul {
            margin-top: $space-2;
            margin-bottom: $space-2;
            font-family: $font-default;
        }
    }
}

.#{$css-namespace}section_slider-catalogue {
    margin: $space-5 auto 0;
    width: 100%;

    &.container {
        max-width: 98rem; // Fit to Figma
    }

    .#{$css-namespace}custom-slider {
        .slick {
            &-slide {
                height: auto !important; // Prevent slick height
            }

            &-disabled {
                opacity: 0; // Should be generic
            }
        }

        .#{$css-namespace}section_slider-catalogue_default-card {
            div {
                color: $color-white;
                height: 100%;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                background-color: $color-blue-dark;
                position: relative;
                overflow: hidden;
            }

            .#{$css-namespace}icon {
                right: 0;
                top: 2.4rem;
                pointer-events: none;
                position: absolute;

                &.biseau {
                    width: 6.1rem;
                    height: 12.3rem;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MSIgaGVpZ2h0PSIxMjMiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0ibTYxIC4xNS02MSA2MSA2MSA2MVYuMTVaIiBvcGFjaXR5PSIuMTIiLz48cGF0aCBmaWxsPSIjMDc4OUY0IiBkPSJNNjEgNDIuMTUyIDQyLjAwMSA2MS42NTEgNjEgODEuMTQ4VjQyLjE1MloiLz48L2c+PC9zdmc+);
                }
            }

            span {
                position: relative;
                padding: $space-2 $space-4;
                z-index: 2;
                font-family: $font-secondary;
                font-weight: $font-weight-bold;
                font-size: $font-size-xxl;

                @include min-screen($screen__l) {
                    font-size: $font-size-xl;
                }
            }
        }

        &_slide {
            padding: 0 1rem;
            color: $color-blue-dark;

            a {
                overflow: hidden;
                background-color: $color-blue-dark;
                position: relative;

                figure {
                    position: relative;
                    width: 100%;

                    figcaption {
                        position: absolute;
                        z-index: 2;
                        width: 100%;
                        height: $slider-figcaption-height;
                        overflow: hidden;
                        bottom: 0;
                        left: 0;
                        background-color: rgba($color-primary, 0.8);
                        transition:
                            transform var(--transition-speed-normal),
                            opacity var(--transition-speed-normal);
                        color: $color-white;

                        p {
                            overflow: hidden;
                            max-height: $slider-figcaption-height - 1.2rem;
                            margin-bottom: 0;
                            line-height: 2;
                            padding: $space-1;
                        }

                        strong {
                            font-weight: $font-weight-black;
                            display: block;
                            min-height: $space-3;
                            overflow: hidden;
                            max-height: 9rem;
                        }

                        span {
                            padding: $space-1 0;
                            display: block;
                            line-height: 1.4;
                            font-size: $font-size-xs;
                        }

                        &::after {
                            font-family: $font-icons;
                            content: $caret-right;
                            position: absolute;
                            right: $space-1;
                            bottom: 1rem;
                            line-height: 1;
                            font-size: $font-size-m;
                        }
                    }
                }

                @include lib-hover-focus {
                    color: $color-white;

                    figure {
                        figcaption {
                            opacity: 0;
                            transform: translateY(100%);
                        }
                    }
                }
            }
        }
    }
}
